<template>
  <v-layout wrap v-if="editedItem">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">{{ editedItem.ID ? 'mdi-folder-edit' : 'mdi-folder-plus' }}</v-icon>
      <v-toolbar-title>
        {{ editedItem.ID ? 
            $t('folders.editFolder', locale) :
            editedItem.Level > 0 ?
            $t('folders.addFolder', locale) :
            $t('folders.createFolder', locale) 
        }}
      </v-toolbar-title>
    </v-toolbar>
    <!-- <v-flex xs12>
      <h1>{{ editedItem.ID ? $t('folders.editFolder', locale) : $t('folders.addFolder', locale) }}</h1>      
    </v-flex> -->
    <v-flex xs12 style="padding:20px 20px 0 20px">
      <translate
        v-model="editedItem.Name"
        :languages="languages"
        :label="$t('folders.name', locale)"
        @input="handleChangeIsModified"
        type="text"
      />
    </v-flex>
    <v-flex xs12 v-if="editedItem.Level === 0">
      <select-visibility
        v-model="editedItem.Visibility"
        @change="handleChangeVisibility"
        :locale="locale"
      />
    </v-flex>
    <v-flex xs12 v-if="editedItem.Level === 0 && showSelectCustomer">
      <select-customers
        area="dfiles"
        :folderID="editedItem.ID"
        :onChangeCustomer="handleChangeCustomer"
      />
    </v-flex>
    <v-flex xs12>
      <action-buttons
        :id="editedItem.ID"
        :onDeleteItem="onDelete"
        :onSaveItem="handleSave"
        :onClose="onClose"
        :locale="locale"
      />
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import Translate from '@/components/Translate'
  import ActionButtons from '@/components/ActionButtons'
  import SelectCustomers from './SelectCustomers'
  import SelectVisibility from './SelectVisibility'
  export default {
    name: 'folder-form',
    components: { Translate, ActionButtons, SelectCustomers, SelectVisibility },
    props: {
      // editedItem: {
      //   type: Object,
      //   required: true
      // },
      data: {
        type: Object,
        required: true
      },
      onDelete: {
        type: Function,
        required: true
      },
      onClose: {
        type: Function,
        required: true
      }
    },
    data () {
      return {
        editedItem: null,
        showSelectCustomer: false,
      }
    },
    computed: {        
      ...mapState('app', ['locale']),
      languages () {
        return this.$store.state.app.languages
      }
    },
    watch: {
      data (v) {
        this.editedItem = JSON.parse(JSON.stringify(v))
        this.showSelectCustomer = this.editedItem && this.editedItem.Visibility === 'privateByUser'
      },
    },
    mounted () {
      this.editedItem = JSON.parse(JSON.stringify(this.data))
      this.showSelectCustomer = this.editedItem && this.editedItem.Visibility === 'privateByUser'


    // // tmp
    //   for (let i = 0; i < 1000; i ++) {
    //     this.editedItem.Name.es = 'folder ' + i
    //     api.addItem ('dfiles', 'private/folders/', this.editedItem)
    //       .then((response) => {
    //         console.log('jhm', i)
    //       })
    //   }
    //   // tmp
    },
    methods: {
      handleChangeIsModified () {
        // alert('aux')
      },
      handleChangeVisibility (v) {
        this.editedItem.Visibility = v
        this.showSelectCustomer = this.editedItem.Visibility === 'privateByUser'
        console.log('jhm2', this.editedItem)
      },
      handleChangeCustomer (v) {
        this.editedItem.Customers = v
        this.editedItem.CustomerSec = v && v.length > 0
      },
      handleSave () {        
        if (!this.editedItem.ID)
          api.addItem ('dfiles', 'v1/private/folders/', this.editedItem)
          .then((response) => {
            let aux = null
            if (response.data && response.data.length > 0) {
              aux = response.data[0]
              aux.expand = this.editedItem.expand
              aux.ItemsCount = this.editedItem.ItemsCount
            }
            this.onClose('add', aux)
          })
        else 
          api.updateItem ('dfiles', 'v1/private/folders/', this.editedItem.ID, this.editedItem)
          .then((response) => {
            let aux = null
            if (response.data && response.data.length > 0) {
              aux = response.data[0]
              aux.expand = this.editedItem.expand
              aux.ItemsCount = this.editedItem.ItemsCount
            }
            this.onClose('update', aux)
          })
      }
    }
  }
</script>
